//******** Color ********//
$white: #ffffff;
$black: #000000;

$primary-color: #0369dd;
$secondary-color: #354463;

$error: #fd3c4b;
$success: #01d195;
$warning: #ff6c00;
$info: #0369dd;
$disable: #ff8b46;

// Grey Shade
$grey-25: #b8b8b8;
$grey-50: #f4f4f4;
$grey-100: #eff3fa;
$grey-125: #eff7ff; // input backgrounds
$grey-150: #fff0f0;
$grey-175: #f1f1f1;
$grey-200: #f9fbff;
$grey-300: #f0f6f8;
$grey-350: #f6f8fb;
$grey-400: #eff1f6;
$grey-500: #cddae0;
$grey-545: #bbc5c9;
$grey-550: #bbc2d0;
$grey-600: #afb8bc;
$grey-650: #888;
$grey-700: #97a2a8;
$grey-800: #6e7a93;
$grey-850: #555;
$grey-900: #354463;
$grey-911: #f7f9fc;

// Blue Shade
$blue-100: #dff0fd;
$blue-200: #e6f1fe;
$blue-210: #f0f6fd;
$blue-220: #d0e6ff;
$blue-300: #aacef8;
$blue-350: #e5f0fb;
$blue-400: #e5f0fc;
$blue-450: #117af5;
$blue-500: #459bff;
$blue-520: #50617c;
$blue-550: #334467;
$blue-600: $primary-color;
$blue-610: #293350;
$blue-700: #0466bf;
$blue-800: #0058bc;
$blue-900: #0056b9;
$blue-910: #67728b;
$blue-920: #237feb;

//fff0f0
//eff7ff
//fca0a0
//f8dbdc
//ffd1d1

/* Replace Below Colors

 #0257a3 => $primary-color
 #00b27f => $success
 #fa7628 => $warning

*/

// Green Shade
$green-100: #a5efd9;
$green-200: #1bad3c;
$green-400: #2ec14f;
$green-500: #0dd297;
$green-600: #008e65;

// Yellow Shade
$yellow-100: #e0e0e0;
$yellow-300: #fff9e6;
$yellow-600: #ffc402;

// Red Shade
$red-100: #ffd1d1;
$red-200: #ffd8d8;
$red-250: #ffd9ee;
$red-300: #fca0a0;
$red-600: #fd3c4b;
$red-800: #e02836;
$red-900: #e21e1e;

// Card Status Colors
$card-active-color: #55ca70;
$card-inactive-color: #a3a3a3;
$card-blocked-color: #db2828;
$card-hotlisted-color: #ff9300;
$card-terminated-color: #ff4bac;
$card-unassigned-color: #459bff;
$card-all-color: #07cdd5;

// Card Status Dot and text Colors
$carddot-active-color: #55ca70;
$carddot-inactive-color: #bebebe;
$carddot-blocked-color: #db2828;
$carddot-hotlisted-color: #ff7f00;
$carddot-terminated-color: #ff4bac;
$carddot-unassigned-color: #459bff;
$carddot-all-color: #07cdd5;

// Card Issue Status backgroud <= Used in Track Application
$card-issue-bk-delivered: #ddfdf4;
$card-issue-bk-notapplicable: $grey-500;
$card-issue-bk-notdispatched: #fff2c6;
$card-issue-bk-dispatched: #ffefdb;

$card-issue-textcolor-delivered: $success;
$card-issue-textcolor-notapplicable: $grey-800;
$card-issue-textcolor-notdispatched: #a78a29;
$card-issue-textcolor-dispatched: $warning;
$card-issue-status-bar-background: #a7b1ba;

// Payment Status
$payment-approved: #55ca70;
$payment-notapplicable: $grey-500;
$payment-pending: #ffc502;
$payment-paid: #55ca70;
$payment-rejected: #db2828;

// Profile Cards background color
$stripe-blue: #0f91a7;
$stripe-gold: #a0800c;
$stripe-platimium: #637489;
$stripe-platimium-plus: #3d3d81;
$stripe-signature: #363648;
$stripe-silver: #777777;

// Service Management
$divider-color: #eee;
/*
colors getting used only in SVG's
Green Shade
$green-100: #79f3c1; // Only going to use in SVG
$green-200: #84ead2; // Only going to use in SVG

Cyan Shade
$cyan-200: #79e2f3; // Only going to use in SVG
*/

//******** Font Property *************//

// Font Family
$open-sans: 'Open Sans', sans-serif;
$raleway: 'Raleway', sans-serif;

// Font Weight
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

// used for raleway font
$font-weight-medium: 500;

//******** Responsiveness mixins ********//

@mixin for-phone-only {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin for-web-only {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin for-tablet-above {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin for-small-screens-above {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin for-high-resolution-only {
    @media (min-width: 1681px) {
        @content;
    }
}

//******** Text mixins ********//

/*
    *** Header - After Login Screens ***
    VD Team Specify this font as : h2
    Raleway - Medium
    28px
    Mobile screen : 20px

    *** Popup Header ***
    Raleway - Medium
    20px
    Mobile screen : 18px

*/

// Raleway - 28 px
@mixin rl-28-bold {
    font-size: 1.75rem;
    font-weight: $font-weight-bold;
    font-family: $raleway !important;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.77rem;
    }
}
@mixin rl-28-semibold {
    font-size: 1.75rem;
    font-family: $raleway !important;
    font-weight: $font-weight-semibold;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.77rem;
    }
}
@mixin rl-28-medium {
    font-size: 1.75rem;
    font-family: $raleway !important;
    font-weight: $font-weight-medium;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.77rem;
    }
}
@mixin rl-24-semibold {
    font-size: 1.6rem;
    font-family: $raleway !important;
    font-weight: $font-weight-semibold;
    @include for-phone-only {
        font-size: 1.5rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.62rem;
    }
}

@mixin rl-24-bold {
    font-size: 1.6rem;
    font-family: $raleway !important;
    font-weight: $font-weight-bold;
    @include for-phone-only {
        font-size: 1.5rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.62rem;
    }
}

@mixin rl-22-medium {
    font-size: 1.375rem;
    font-family: $raleway !important;
    font-weight: $font-weight-medium;
    @include for-high-resolution-only() {
        font-size: 1.395rem;
    }
}
@mixin rl-22-semibold {
    font-size: 1.375rem;
    font-family: $raleway !important;
    font-weight: $font-weight-semibold;
    @include for-high-resolution-only() {
        font-size: 1.395rem;
    }
}
@mixin rl-18-medium {
    font-size: 1.25rem;
    font-family: $raleway !important;
    font-weight: $font-weight-medium;
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}
@mixin rl-28 {
    font-size: 1.75rem;
    font-family: $raleway !important;
    font-weight: $font-weight-normal;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.79rem;
    }
}

// Raleway - 20 px
@mixin rl-20-bold {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    font-family: $raleway !important;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}
@mixin rl-20-semibold {
    font-size: 1.25rem;
    font-weight: $font-weight-semibold;
    font-family: $raleway !important;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}
@mixin rl-20-medium {
    font-size: 1.25rem;
    font-weight: $font-weight-medium;
    font-family: $raleway;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}
@mixin rl-20 {
    font-size: 1.25rem;
    font-family: $raleway !important;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}

// Open-sans
@mixin os-bold {
    font-weight: $font-weight-bold;
    font-family: $open-sans !important;
    color: $grey-900;
}
@mixin os-semibold {
    font-weight: $font-weight-semibold;
    font-family: $open-sans !important;
    color: $grey-900;
}
@mixin os-medium {
    font-weight: $font-weight-medium;
    font-family: $open-sans !important;
    color: $grey-900;
}
@mixin os {
    font-weight: $font-weight-normal;
    font-family: $open-sans !important;
    color: $grey-900;
}

// Open-sans 28 px
@mixin os-28-bold {
    @include os-bold;
    font-size: 1.75rem;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.79rem;
    }
}

@mixin os-28-semibold {
    @include os-semibold;
    font-size: 1.75rem;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.79rem;
    }
}
@mixin os-28 {
    @include os;
    font-size: 1.75rem;
    font-family: $open-sans;
    color: $grey-900;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.79rem;
    }
}

// Open-sans 24 px
@mixin os-24-bold {
    @include os-bold;
    font-size: 1.5rem;
    @include for-phone-only {
        font-size: 1.25rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.52rem;
    }
}
@mixin os-24 {
    @include os;
    font-size: 1.5rem;
    font-family: $open-sans;
    color: $grey-900;
    @include for-phone-only {
        font-size: 1.375rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.52rem;
    }
}

// Open-sans 22 px
@mixin os-22-bold {
    @include os-bold;
    font-size: 1.375rem;
    @include for-high-resolution-only() {
        font-size: 1.39rem;
    }
}
@mixin os-22-semibold {
    @include os-semibold();
    font-size: 1.375rem;
    @include for-high-resolution-only() {
        font-size: 1.395rem;
    }
}
@mixin os-22 {
    @include os;
    font-size: 1.375rem;
    @include for-high-resolution-only() {
        font-size: 1.395rem;
    }
}

// Open-sans 20 px
@mixin os-20-bold {
    @include os-bold;
    font-size: 1.25rem;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}
@mixin os-20-semibold {
    @include os-semibold;
    font-size: 1.25rem;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}
@mixin os-20-medium {
    @include os-medium;
    font-size: 1.25rem;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}
@mixin os-20 {
    @include os;
    font-size: 1.25rem;
    @include for-phone-only {
        font-size: 1.125rem;
    }
    @include for-high-resolution-only() {
        font-size: 1.27rem;
    }
}

// Open-sans 18 px
@mixin os-18-bold {
    @include os-bold;
    font-size: 1.125rem;
    @include for-high-resolution-only() {
        font-size: 1.145rem;
    }
}
@mixin os-18-semibold {
    @include os-semibold;
    font-size: 1.125rem;
    @include for-high-resolution-only() {
        font-size: 1.135rem;
    }
}
@mixin os-18 {
    @include os;
    font-size: 1.125rem;
    @include for-high-resolution-only() {
        font-size: 1.135rem;
    }
}

// Open-sans 16 px
@mixin os-16-bold {
    @include os-bold;
    font-size: 1rem;
    @include for-high-resolution-only() {
        font-size: 1.02rem;
    }
}
@mixin os-16-semibold {
    @include os-semibold;
    font-size: 1rem;
    @include for-high-resolution-only() {
        font-size: 1.02rem;
    }
}
@mixin os-16 {
    @include os;
    font-size: 1rem;
    @include for-high-resolution-only() {
        font-size: 1.02rem;
    }
}

// Open-sans 14 px
@mixin os-14-bold {
    @include os-bold;
    font-size: 0.875rem;
    @include for-phone-only() {
        font-size: 0.875rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.895rem;
    }
}
@mixin os-14-semibold {
    @include os-semibold;
    font-size: 0.875rem;
    @include for-phone-only() {
        font-size: 0.875rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.895rem;
    }
}
@mixin os-14 {
    @include os;
    font-size: 0.875rem;
    @include for-phone-only() {
        font-size: 0.875rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.89rem;
    }
}

// Open-sans 13 px
@mixin os-13-bold {
    @include os-bold;
    font-size: 0.813rem;
    @include for-phone-only() {
        font-size: 0.813rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.833rem;
    }
}
@mixin os-13-semibold {
    @include os-semibold;
    font-size: 0.813rem;
    @include for-phone-only() {
        font-size: 0.813rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.833rem;
    }
}
@mixin os-13-medium {
    @include os-medium();
    font-size: 0.813rem;
    @include for-phone-only() {
        font-size: 0.813rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.833rem;
    }
}

@mixin os-13 {
    @include os;
    font-size: 0.813rem;
    @include for-phone-only() {
        font-size: 0.813rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.833rem;
    }
}

// Open-sans 12 px
@mixin os-12-bold {
    @include os-bold;
    font-size: 0.75rem;
    @include for-phone-only() {
        font-size: 0.75rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.77rem;
    }
}
@mixin os-12-semibold {
    @include os-semibold;
    font-size: 0.75rem;
    @include for-phone-only() {
        font-size: 0.75rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.77rem;
    }
}
@mixin os-12 {
    @include os;
    font-size: 0.75rem;
    @include for-phone-only() {
        font-size: 0.75rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.77rem;
    }
}

// Open-sans 11 px
@mixin os-11-bold {
    @include os-bold;
    font-size: 0.688rem;
    @include for-phone-only() {
        font-size: 0.688rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.7rem;
    }
}
@mixin os-11-semibold {
    @include os-semibold;
    font-size: 0.688rem;
    @include for-phone-only() {
        font-size: 0.688rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.7rem;
    }
}
@mixin os-11 {
    @include os;
    font-size: 0.688rem;
    @include for-phone-only() {
        font-size: 0.7rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.7rem;
    }
}

// Open-sans 10 px
@mixin os-10-bold {
    @include os-bold;
    font-size: 0.625rem;
    @include for-phone-only() {
        font-size: 0.625rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.64rem;
    }
}
@mixin os-10-semibold {
    @include os-semibold;
    font-size: 0.625rem;
    @include for-phone-only() {
        font-size: 0.625rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.64rem;
    }
}
@mixin os-10 {
    @include os;
    font-size: 0.625rem;
    @include for-phone-only() {
        font-size: 0.625rem;
    }
    @include for-high-resolution-only() {
        font-size: 0.64rem;
    }
}
