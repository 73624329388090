.upload_image{
    position: relative;
}
.upload_image > input{
    width: 50px;
    height: 25px !important;
    position: absolute;
    left: 0;
    opacity: 0;
}
.image_name{
    font-weight: initial;
}
.upload-btn{
    display: inline-block;
    padding: 12px 15px;
    background: #86bc25;
    border: 1px solid #86bc25;
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: all ease 0.4s;
    border-radius: 6px;
    font-weight: 700;
}
