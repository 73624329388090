.login {
  display: flex;
  flex-direction: column;
  /* align-items: center;
    justify-content: center; */
  margin-top: 100px;
  margin-left: 330px;
  height: 80vh;
}

/* .login__form {
    display: flex;
    flex-direction: column;
} */

.login__form > h1 {
  font-style: 20px;
  margin-bottom: 15px;
  font-size: 20px;
  padding-bottom: 10px;
  letter-spacing: -1px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

/* .login__form > input {
    /* padding: 15px 0px; */
/* padding-left: 10px; */
/* margin-bottom: 5px; */
/* ; 
    display: flex;
    float: right; */
/* flex:0 0 200px; */
/* width:70%; */
/* border: 1px solid black;
    border-radius: 2px; */
/* font-size: 15px; */

/* }  */
.inputBox {
  margin-left: 50px;
  width: 70%;
  border-color: black;
}
.inputBoxPass {
  margin-left: 18px;
  width: 70%;
  border-color: black;
}

.error-msg {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: right;
  color: red;
  padding: 2px;
  /* width: 100%; */
  /* margin-right:150px */
}
#span {
  margin-top: 3px;
}
.forgot {
  /* padding-top: 0.35em; 
     padding-bottom: 4.35em;    */
  padding: 40px 0px 35px 100px;
}

.height-vh {
  height: 100vh;
}
.login-wrap {
  padding: 70px 0;
}
.login-wrap .logo {
  padding: 0 119px;
}
.table-wrap {
  display: table;
  width: 100%;
  height: 100%;
}
.align-wrap {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.form-wrap h2 {
  font-size: 52px;
  font-weight: bold;
}
.form-wrap {
  max-width: 540px;
  margin: 0 auto;
}
.form-group {
  margin-bottom: 20px;
}
.forgotpassword {
  text-align: right;
  display: inline-block;
  width: 100%;
}
.forgotpassword a {
  color: #86bc25;
  font-weight: 600;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 16px;
}
.thame-btn {
  display: inline-block;
  padding: 12px 15px;
  background: #86bc25;
  border: 1px solid #86bc25;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all ease 0.4s;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 700;
}
.form-group {
  position: relative;
}
.form-group .pass {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #b4b2b2;
}
.login-wrap .inner-img {
  margin-top: 186px;
}
.form-wrap {
  margin-top: 190px;
  padding-bottom: 50px;
}
.footer_section {
  position: relative;
}
.footer_section_child {
  position: absolute;
  bottom: 16px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 8px;
}
.footer_section ul li {
  display: inline-block;
}
.cantrol_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}
.copyright_text {
  text-align: center;
  text-align: 36px;
}
/* requirment main*/
.nav_class a.nav-link {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.nav_class .navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.inner_page .form-control {
  height: 52px;
}
.link {
  background: transparent;
  border: none;
  color: green !important;
  padding-bottom: 20px;
  margin-top: 0px;
}
