/* Common CSS */
body {
  color: #2e3138;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42857143;
  text-rendering: auto;
  background: #f5f6f7;
}
*,
*:focus {
  outline: none;
}
a {
  color: #2e3138;
  transition: all ease 0.4s;
  text-decoration: none;
}
a:focus {
  outline: none;
  outline-offset: 0;
}
a:hover,
a:focus {
  color: #86bc25;
  text-decoration: none;
}
.btn-primary:hover {
  background-color: #6a9b13;
  text-decoration: none;
}
.btn-primary {
  color: #fff;
  background-color: #86bc25;
  border-color: #86bc25;
}
img {
  max-width: 100%;
  height: auto;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  padding-bottom: 10px;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.3;
  color: #000000;
}
p {
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #2e3138;
  font-size: 14px;
  line-height: 24px;
}
button,
button:focus {
  outline: none;
  outline-offset: 0;
}
figure {
  margin: 0;
}
iframe {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  border: none;
}
video {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
::selection {
  background: #86bc25;
  color: #ffffff;
}
::-webkit-selection {
  background: #86bc25;
  color: #ffffff;
}
::-moz-selection {
  background: #86bc25;
  color: #ffffff;
}
::-o-selection {
  background: #86bc25;
  color: #ffffff;
}
::-ms-selection {
  background: #86bc25;
  color: #ffffff;
}
::-webkit-input-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}
::-moz-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}
:-moz-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}

.height-vh {
  height: 100vh;
}


.page-wrap .container {
  padding: 0px 50px;
  width: 1024px;
  max-width: 1024px;
}

.banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /*min-height: 335px;*/
  /*position: relative;*/
  margin-top: 10px;
}

.bannertext {
  background: #fff;
  border-right: solid 15px #6a9b13;
  width: 65%;
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 0;*/
  /*transform: translateY(-50%);*/
  padding: 15px 30px 30px 0px;
}

.logoWeb {
  height: 100px;
  width: 180px;
}

.bannerheading {
  font-size: 28px;
  color: #000;
  font-weight: 700;
  line-height: 38px;
  border-bottom: solid 1px #007cb0;
  padding-bottom: 10px;
}

.doucment_information_two table tr td {
  border-bottom: none;
  padding: 5px;
}

.bannertext p {
  font-size: 16px;
  color: #6e6e6e;
  padding-top: 10px;
}

.doucment_information {
  margin: 20px 0;
}
h2 {
  font-size: 14px;
  text-transform: capitalize;
}

.style-two td {
  width: 50%;
}

.styled-two td {
  width: 30%;
  padding: 8px;
  border-bottom: none;
}

.doucment_information .table-responsive {
  background-color: #fff;
  padding: 10px 8px;
}

.doucment_information td span {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
}

#divToPrint td {
  white-space: pre-line;
}

.doucment_information td {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}
.doucment_information.style-two table tr:nth-child(odd) {
  background-color: #edf0f4;
}
.doucment_information.style-two table tr:nth-child(even) {
  background-color: #fff;
}

.doucment_information.style-three {
  margin-top: 0px;
}

.doucment_information.style-three table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.doucment_information.style-three table thead tr {
  background-color: #86bc25;
}

.styled-two table thead tr {
  background-color: #86bc25;
}

.doucment_information.style-three table tbody tr:nth-child(even) {
  background-color: #edf0f4;
}

.customer-contact {
  font-weight: 600;
  font-size: 14px;
  padding: 0px 0 10px 0;
}

.doucment_information thead {
  background-color: #86bc25;
  color: #fff;
}

.doucment_information_two {
  margin: 20px 0;
  background-color: #fff;
  padding: 10px 8px;
  font-size: 16px;
  line-height: 22px;
}

.doucment_information_two h3 {
  font-size: 14px;
  padding: 16px 0;
}

.doucment_information_two table {
  margin-bottom: 10px;
  /* border-bottom: solid 1px #edf0f4; */
}

.doucment_information_two .text-end {
  padding-right: 16px;
}

.doucment_information_two thead span {
  font-weight: 700;
  display: inline-block;
  padding-left: 10px;
}

.doucment_information_two tbody span {
  display: inline-block;
  padding-left: 20px;
}

.field_mappings {
  margin-top: 25px;
}

@media screen and (max-width: 1199px) {
  .page-wrap .container {
    padding: 0 15px;
  }
}

@media print {
  @page {
    size: auto;   /* auto is the initial value */
    margin: 0;  /* this affects the margin in the printer settings */
  }
  /* div {
    break-inside: avoid;
  } */

  .unbreak_div div {
    break-inside: avoid;
  }

  /* .styled-two table {
    break-inside: avoid;
  } */

  .container {
    padding: 0px;
    border: none !important;
  }
  .page-break {
    page-break-after: always;
  }
  
  .doucment_information.style-three {
    page-break-before: always;
    margin-top: 25px;
  }

  table.print-friendly tr td, table.print-friendly tr th {
    page-break-inside: avoid;
}
}
