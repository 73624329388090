.loginFooter
{ 
     /* position:fixed; */
     margin-left:80px;
     line-height: 10em;
     font-family: Arial, Helvetica, sans-serif; 
     font-size: 15px;

} 

