.contactsForm{
    margin-left: 2px;
    display: inline-block;
    /* margin-right: 2px; */
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border-color: black;
    background-color: white;
      height: 850px; 
     /* top:-50px;  */
    width:auto; 
}
.button_cancel {
   
    width: 5%;
    height: 20px; 
}

.button_create {
   
    width: 5%;
    height: 20px; 
}