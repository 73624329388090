.emailField {
    /* width: 20px; */
     margin-left: 2px;
    display: block; 
    /* margin-right: 2px;
    padding-top: 0.35em; 
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em; */
    height: 30vh;
 background-color: white; 
    border-color: black;
    width:400px

}
.emailSuccess {
    display: flex;
    flex-direction: column; 
    margin-right:200px;
     margin-top: 100px;
    margin-left:350px;
      
       text-align: center;
}
