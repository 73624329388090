.customer_form {
  display: flex;
  flex-direction: column;
  /* align-items: center;
        justify-content: center; */
  margin-left: 15px;
  height: 80vh;
}

.customerField {
  /* width: 1250px; */
  margin-left: 2px;
  display: inline-block;
  margin-right: 2px;
  /* padding-top: 0.2em; */
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  /* border: 2px groove (internal value); */
  border-color: "#e1e3e6";
  height: 750px;
  top: -50px;
  width: auto;
  /* height:auto  */
}

.labelfield {
  /* display: inline-flexbox; */
  white-space: nowrap;
  display: block;
}

.form_field {
  display: inline-block;
}

.field1 {
  float: left;
}
.field2 {
  float: left;
  margin-left: 150px;
}

.form1 {
  float: left;
}
.form2 {
  float: left;
  margin-left: 150px;
}

.dropbutton {
  color: black;
  padding: 5px;
}

/* body {
  padding-top: 20px;
} */

.invalid-feedback {
  display: block !important;
}

.container {
  display: flex;
  flex-direction: column;
  /* margin: "auto"; */
  height: auto;
  border: solid;
  /* margin: 1rem; */
  padding: 40px;
  border-color: #dcd7d7;
}
.container .sub-container {
  width: 50%;
  float: left;
}

.container label {
  height: auto;
  margin: 0px;
  text-align: left;
  align-items: flex-start;
}

.container input {
  width: 90%;
  margin-top: 0rem;
  margin-bottom: 1rem;
  height: 30px;
}

.container select {
  width: 90%;
  margin-top: 0rem;
  margin-bottom: 1rem;
  height: 30px;
}

.container select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
.container textarea {
  width: 90%;
  margin-top: 0rem;
  margin-bottom: 1rem;
  height: 100px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(185, 182, 182);
  opacity: 1; /* Firefox */
}

label {
  display: block;
  margin: 16px 0;
}

select {
  color: black;
}

.placeholder {
  color: gray;
}

.button {
  background-color: #1976d2; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 10px;
}

.form-control {
  margin-bottom: 1rem;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #c0d4fd;
}

.control-group .form-control {
  min-width: 15rem;
  flex: 1;
}

button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover button:active {
  background-color: #33059e;
  border-color: #33059e;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40c0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  margin-top: -17px;
  color: #b40e0e;
}
