.reset {
  display: flex;
  flex-direction: column;
  /* align-items: center;
    justify-content: center;  */
  margin-top: 100px;
  margin-left: 330px;
  height: 80vh;
}
.reset_form > h1 {
  font-style: 20px;
  margin-bottom: 15px;
  font-size: 20px;
  padding-bottom: 10px;
  letter-spacing: -1px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}
.field {
  width: 300px;
  margin-left: 2px;
  display: block;
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  background-color: #6b8e23;
  border-color: black;
}

.inputBox {
  margin-left: 50px;
  width: 70%;
  height: 30px;
  border-color: black;
}
.inputBoxPass {
  margin-left: 18px;
  width: 74%;
  height: 30px;
  border-color: black;
}
