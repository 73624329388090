#create{
    /* float:right */
    background-color: #9ACD32;
    color: black;
text-align: center;}
#cancelButton{
    background-color: lightcoral;
    color:black
    
}
.buttons{
    display: flex;
}
