@import "../../../assets/scss/scss/_base.scss";

.cursorPointer {
  cursor: pointer;
}

.noOpacity {
  opacity: 0;
}
.button {
  // min-height: 40px;
  width: auto;
  @media only screen and (max-width: 576px) {
    width: 250px;
    text-align: center;
  }
}
.orderIdsList {
  width: 200px;
  height: 40px;
  margin: 15px 10px 15px 10px;
  padding: 8px 10px;
  text-align: center;
  float: left;
  border-radius: 4px;
  border: solid 1px $grey-700;
  background-color: $grey-911;
}
.popStyles {
  color: #0257a3;
}
.pc {
  text-align: center;
}
.description {
  text-align: center;
}
.vehicleId {
  display: flex;
  justify-content: center;
}
.icon {
  width: 75px;
}

