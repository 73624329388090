@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
/* Common CSS */
body {
  color: #2e3138;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42857143;
  text-rendering: auto;
  background: #f5f6f7;
}
*,
*:focus {
  outline: none;
}
a {
  color: #2e3138;
  transition: all ease 0.4s;
  text-decoration: none;
}
a:focus {
  outline: none;
  outline-offset: 0;
}
a:hover,
a:focus {
  color: #86bc25;
  text-decoration: none;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #6a9b13;
  text-decoration: none;
  box-shadow: none;
}
.btn-primary {
  color: #fff;
  background-color: #86bc25;
  border-color: #86bc25;
}
img {
  max-width: 100%;
  height: auto;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  padding-bottom: 10px;
  margin-bottom: 0;
  line-height: 1.3;
  color: #000000;
  font-size: 24px;
}
p {
  padding-bottom: 10px;
  margin-bottom: 0;
  color: #2e3138;
  font-size: 13px;
  line-height: 24px;
}
button,
button:focus {
  outline: none;
  outline-offset: 0;
}
figure {
  margin: 0;
}
iframe {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  border: none;
}
video {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
::selection {
  background: #86bc25;
  color: #ffffff;
}
::-webkit-selection {
  background: #86bc25;
  color: #ffffff;
}
::-moz-selection {
  background: #86bc25;
  color: #ffffff;
}
::-o-selection {
  background: #86bc25;
  color: #ffffff;
}
::-ms-selection {
  background: #86bc25;
  color: #ffffff;
}
::-webkit-input-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}
::-moz-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}
:-moz-placeholder {
  color: #b4b2b2 !important;
  opacity: 1;
}
.form-control {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #b4b2b2;
  font-size: 13px;
  color: #000;
  height: 40px !important;
  font-weight: 600;
  padding: 12px 22px 12px 12px;
  background-color: #fff;
  transition: all ease 0.4s;
  appearance: none;
  background-color: #fff;
}

.height-vh {
  height: 100vh;
}
.login-wrap {
  padding: 70px 0;
}
.login-wrap .logo {
  padding: 0 119px;
}
.table-wrap {
  display: table;
  width: 100%;
  height: 100%;
}
.align-wrap {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.form-wrap h2 {
  font-size: 52px;
  font-weight: bold;
}
.form-wrap {
  max-width: 540px;
  margin: 0 auto;
}
.form-group {
  margin-bottom: 20px;
}

.form-select {
  font-size: 14px;
}
.forgotpassword {
  text-align: right;
  display: inline-block;
  width: 100%;
}
.forgotpassword a {
  color: #86bc25;
  font-weight: 600;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 16px;
}
.thame-btn {
  display: inline-block;
  padding: 12px 15px;
  background: #86bc25;
  border: 1px solid #86bc25;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all ease 0.4s;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 700;
}
.thame-btn-danger {
  display: inline-block;
  padding: 12px 15px;
  background: #dc3545;
  border: 1px solid #dc3545;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all ease 0.4s;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 700;
}
.form-group {
  position: relative;
}
.form-group .pass {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #b4b2b2;
}
.login-wrap .inner-img {
  margin-top: 186px;
}
.form-wrap {
  margin-top: 190px;
  padding-bottom: 50px;
}
.footer_section {
  position: relative;
}
.footer_section_child {
  position: absolute;
  bottom: 16px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 8px;
}
.footer_section ul li {
  display: inline-block;
}
.cantrol_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}
.copyright_text {
  text-align: center;
  text-align: 36px;
}
/* requirment main*/
.nav_class a.nav-link {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.nav_class .navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.inner_page .form-control,
.form-select {
  height: 40px;
}
.inner_page .form-label {
  margin-bottom: 0.5rem;
  font-size: 13px;
  color: #2e3138;
  font-weight: 600;
  width: 100%;
}
.main-contant {
  padding: 15px;
}
.main-wrap {
  box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.main-wrap-inner {
  padding: 15px;
  background: #fff;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.main-wrap .tab-content {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.main-wrap .tab-content form [class*="col-"] {
  margin: 0 0 10px;
}
.main-wrap .tab-content .tab-pane {
  padding: 30px 15px;
}
td {
  vertical-align: middle;
}
.footer {
  text-align: center;
  padding: 30px 0;
}
.footer ul {
  list-style: none;
  margin: 0;
}
.footer ul li {
  position: relative;
  display: inline-block;
  padding: 0 5px;
}
.footer ul li:before {
  position: absolute;
  right: 0;
  top: 7px;
  width: 1px;
  height: 15px;
  background-color: #d2d2d2;
  content: "";
  z-index: 1;
}
.footer ul li a {
  color: #d2d2d2;
}
.table-header {
  background-color: #efefef;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 15px;
}
.table-header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.table-header ul li {
  display: inline-block;
  font-size: 14px;
}
.table-header ul li a {
  color: #000;
  display: inline-block;
  padding: 12px 15px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 500;
}
.table-header ul li a.active {
  background-color: #007cb0;
  color: #fff;
}
.table-header ul li {
  margin-right: 28px;
}
.table-header ul li:last-child {
  margin: 0;
}
.inner_page .name a {
  color: #007cb0;
}
.inner_page .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: solid 1px #007cb0;
  border-bottom: solid 1px #fff;
}
.inner_page .nav-tabs {
  border-bottom: solid 1px #007cb0;
}
.inner_page .main-wrap .tab-content {
  border: 1px solid #007cb0;
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #007cb0 #007cb0 #007cb0;
  isolation: isolate;
}
.inner_page .nav-tabs .nav-link {
  padding-left: 41px;
  padding-right: 41px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-top: 10px;
}
.inner_page h2 {
  margin: 20px 0;
}
.customername {
  font-size: 18px;
}
.form-select {
  /*line-height: 2.5;*/
}
/*menu*/
.dropdown:hover .dropdown-menu {
  display: block;
  padding-top: 26px;
  background: none;
}
.dopdown_list {
  background: #393939;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.header .form-control {
  height: auto;
}
.header .navbar-expand .navbar-nav .nav-link {
  padding-left: 50px;
  padding-right: 50px;
}
.dropdown-menu {
  border: none;
}
.dropdown-item {
  padding: 19px 45px 18px 2px;
  color: #bbbcbc;
  border-bottom: solid 1px #6e6e6e;
  margin-left: 10px;
  width: 90%;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background: none;
  color: #86bc25;
}
.dropdown-item:last-child {
  border-bottom: none;
}
.has-search .form-control {
  padding-left: 2.375rem;
}
.dropdown-toggle::after {
  display: none;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  margin-top: 5px;
}
.navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.navbar .form-group {
  margin-bottom: 0;
  width: 332px;
}
.user_box {
  display: contents;
}
.user_profile {
  width: 61px;
  height: 61px;
  margin-left: 20px;
  border: solid 1px #2e3138;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
span.user_profile_green {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border: solid 1px #fff;
  background-color: #86bc25;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.user_name {
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  float: left;
}
.user_name span {
  display: block;
  font-size: 16px;
  font-weight: bold;
}
.down_arrow_user {
  color: #fff;
  font-size: 20px;
  margin-top: 23px;
  margin-left: 10px;
}
.profilewrap .dropdown-menu {
  margin-left: 0px;
  margin-left: -208px;
}
.profile_menu a {
  border-bottom: none;
  min-width: 113px;
  position: relative;
}
.profile_menu a span {
  position: absolute;
  right: 10px;
}
.profile_text {
  display: flex;
}
.profile_text span {
  padding: 12px 10px 0px 5px;
  color: #fff;
  margin-left: 10px;
  font-weight: bold;
}
.profile_menu a {
  padding-top: 12px;
  padding-bottom: 12px;
}
.saprate {
  width: 100%;
  border-bottom: solid 1px #b4b2b2;
  margin: 10px 0;
}
span.manager_profile {
  font-weight: 400;
  color: #bbbcbc;
}
.nav_class .navbar-dark .navbar-nav .nav-link:hover {
  color: #86bc25;
}
.nav_class .navbar-dark .navbar-nav .nav-link.active {
  color: #86bc25;
}
ul.navbar-nav.me-auto.mb-2.mb-lg-0 li {
  padding: 0 32px;
}
ul.navbar-nav.me-auto.mb-2.mb-lg-0 {
  margin: 0 auto;
}
.profile_wrapper {
  margin-top: -10px;
}
.form-control:disabled,
.form-control[readonly] {
  background: #eee;
  color: #000;
  border: none;
}
.form-control[readonly]::placeholder {
  color: #000 !important;
}
textarea {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #b4b2b2;
  font-size: 16px;
  color: #000;
  height: 108px;
  font-weight: 600;
  background-color: #fff;
  transition: all ease 0.4s;
  appearance: none;
  background-color: #fff;
  width: 100%;
  line-height: 20px;
  padding-top: 6px;
  margin: 0px;
  padding-left: 16px;
  font-weight: inherit;
}
.form-label {
  position: relative;
  width:100%
}
.form-label .addnew {
  text-align: right;
}
.addnew a {
  color: #007cb0;
  font-size: 14px;
  line-height: 25px;
}
.outer_border {
  border: solid 1px #007cb0;
  border-top: none;
  padding-bottom: 26px;
  margin-bottom: 9px;
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-bottomright: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.frame_outer {
  padding: 26px 20px 0 20px;
}
.switchToggle {
  margin-top: 5px;
}
.switchToggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}
.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 85px;
  max-width: 85px;
  height: 42px;
  background: #d1d1d1;
  display: block;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  position: relative;
  float: right;
}
.switchToggle label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 38px;
  height: 38px;
  background: #fff;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  transition: 0.3s;
}
.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
  background: #046a38;
}
.switchToggle input + label:before,
.switchToggle input + input + label:before {
  content: "No";
  position: absolute;
  top: 11px;
  left: 52px;
  width: 26px;
  height: 26px;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
  font-weight: 700;
}
.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
  content: "Yes";
  position: absolute;
  top: 11px;
  left: 14px;
  width: 26px;
  height: 26px;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
  font-weight: 700;
}
.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.switchToggle label:active:after {
  width: 60px;
}
.toggle-switchArea {
  margin: 10px 0 10px 0;
}
span.requirementsclass {
  font-size: 14px;
  color: #000;
  float: right;
  margin-right: 10px;
  line-height: 40px;
  float: right;
  font-weight: 600;
}
.lead {
  float: right;
  font-size: 12px;
  font-weight: 600;
}
.add_button {
  font-weight: 16px;
  font-weight: 700;
}
.add_button a {
  color: #fff;
}
.vendor_contact {
  font-size: 22px;
  color: #000;
  line-height: 30px;
}
.add_button_table_top {
  background: #007cb0;
  color: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  text-align: center;
  padding: 8px 35px;
  font-size: 16px;
  margin-left: 16px;
}
.add_button_table_top a {
  color: #fff;
}
.lead_download {
  font-size: 13px;
}
tr td {
  font-size: 13px;
  font-weight: 500;
}

tr th {
  font-size: 13px;
  font-weight: bold;
}
tr th img {
  margin-top: 4px;
}
.table_wrapper_main tr {
  min-height: 39px;
  height: 39px;
}
.table_wrapper_main {
  padding: 10px 26px 10px 26px;
  margin: 20px 0 0 0;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.34);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.table_wrapper_main .table-sm > :not(caption) > * > * {
  padding: 10px 10px;
}
.form-check-input[type="checkbox"] {
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  border-radius: 0.25em;
  width: 24px;
  height: 24px;
  /* background:#046A38; */
}
.table_wrapper_main .form-check-input {
  /* background:#046A38; */
}
.form-check-input[type="checkbox"]:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.form-check-input[type="checkbox"]:checked {
  background-color: #046a38;
}
.table_wrapper_main tr td a {
  color: #007cb0;
}
.table_wrapper_main .form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}
.table_wrapper_main .form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.custom_popup .btn-close {
  opacity: 0.5;
  border: solid 2px black;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.custom_popup .modal-header {
  background: #e1e1e1;
}
.custom_popup .modal-title {
  font-size: 14px;
  color: #2e3138;
  font-weight: 500;
  padding-top: 9px;
}
.custom_popup span.requirementsclass {
  float: left;
  font-weight: 400;
}
.add_button {
  width: 100%;
  background: #86bc25;
  height: 50px;
  margin: 25px;
  min-width: 120px;
}
.testcase_list_wrapper {
  background: #f6f6f6;
  min-height: 50px;
}
.test_case_list {
  margin-top: 10px;
  padding-top: 20px;
}
.test_case_list {
  padding: 40px 0;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.test_case_list a {
  color: #007cb0;
}
.add_case_button {
  background: #007cb0;
  border: solid 1px #007cb0;
}
.cancel_button {
  border: solid 1px #b4b2b2;
  background: #fff;
  color: #b4b2b2;
}
.addbutton_top {
  background: #007cb0;
  padding: 10px 0px;
  margin: 0px;
  height: auto;
  color: #fff;
}
.upload_image {
  font-size: 13px;
  color: #000000;
  font-weight: 700;
  line-height: 22px;
  padding-top: 8px;
}
.upload_image img {
  margin-top: -8px;
  margin-right: 10px;
}
.thumbnail_frame {
  border: solid 12px #ececec;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin: 25px;
  padding: 25px;
  margin-left: 0px;
}
.pt-435 {
  padding-top: 35px;
}
.pt-6 {
  padding-top: 60px;
}
.pt-7 {
  padding-top: 70px;
}
.pt-8 {
  padding-top: 80px;
}
.pt-9 {
  padding-top: 90px;
}
.pt-10 {
  padding-top: 100px;
}
#exTab1 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}
#exTab2 h3 {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}
#exTab1 .nav-pills > li > a {
  border-radius: 0;
}
#exTab3 .nav-pills > li > a {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#exTab3 .tab-content {
  color: white;
  background-color: #428bca;
  padding: 5px 15px;
}
.child_tab {
  margin: 00px 30px 30px 30px;
}
.child_tab .inner_page .nav-tabs .nav-link {
  background: #007cb0;
  padding: 0px;
}
.child_tab .nav-link.active {
  border: none;
  background: #007cb0;
  color: #fff;
}
.child_tab .outer_border {
  border: none;
}
.child_tab .frame_outer {
  padding-left: 0px;
}
.bottom_table_field .frame_outer {
  padding-top: 0px;
}
.header-fixed {
  width: 100%;
}
.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
  display: block;
}
.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}
.header-fixed > tbody {
  overflow-y: auto;
  height: 400px;
}
.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
  width: 25%;
  float: left;
}
.header_fix_tr {
  width: 100%;
  background: #f1f1f1;
  text-align: center;
}
.print_box {
  border: solid 1px #c1c1c1;
  height: 171px;
  width: 100%;
  text-align: center;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.print_box:hover {
  background: #f1f1f1;
  border: #c4c4c4;
}
.print_box img {
  width: 64px;
  padding: 36px 0 0px 0;
}
.print_box p {
  padding-top: 20px;
  color: #007cb0;
  font-weight: 600;
}

table tr td {
  border-bottom: 1px solid #b4b2b2;
}
/* 07-09-22 */
.field-table thead tr th {
  font-size: 13px;
  font-weight: 700;
  vertical-align: middle;
  padding: 10px 5px !important;
}
.field-table tbody table tr th {
  background-color: #f1f1f1;
  color: #000;
  font-size: 16px;
}
.field-table tbody tr td {
  padding: 10px;
}
.field-table table tbody {
  max-height: 113px;
  overflow-y: scroll;
}
.field-table .inner-table {
  height: 200px;
  overflow-y: scroll;
}
.field-table .inner-table thead {
  position: sticky;
  top: 0;
}

.table_wrapper_main tr {
  border-bottom: none;
}

.table_wrapper_main tr:last-child {
  border-bottom: none;
}

.modal-dialog-field {
  min-width: 720px;
}
.btn {
  border: transparent;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  outline: transparent;
}
.btn:hover {
  background: #eee;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 4rem;
}

.link {
  background: transparent;
  border: none;
  color: green;
  padding-bottom: 20px;
  margin-top: 0px;
}

.reset-modal .modal-body {
  padding: 35px 30px;
}
.reset-modal .form-group {
  margin-bottom: 15px;
}
.modal-btn {
  font-size: 22px;
  font-weight: 700;
  padding: 12px 15px;
}
.reset-modal-successful .modal-body {
  text-align: center;
  padding: 20px;
}
.reset-modal-successful .modal-body .img-block img {
  width: 60px;
  margin-bottom: 20px;
}
.reset-modal-successful h2 {
  color: #86bc25;
  font-size: 22px;
  font-weight: 700;
}
.reset-modal-successful button {
  margin-top: 20px;
}

.view_customer {
  font-size: 14px;
  font-weight: 700;
}

.view_customer span {
  color: #6a9b13;
}

.customer-module .nav-tabs .nav-link.active,
.customer-module .nav-tabs .nav-item.show .nav-link,
.customer-module .nav-tabs .nav-link:hover,
.customer-module .nav-tabs .nav-link:focus {
  border: none !important;
  border-color: #ffffff !important;
  border-bottom: solid 4px #007cb0 !important;
  color: #007cb0;
}

.customer-module .nav-tabs {
  border-color: #b4b2b2;
}

.c-pro-img {
  width: 203px;
  height: 203px;
  position: relative;
  border: #b4b2b2 solid 1px;
}
.c-pro-upload {
  position: absolute;
  bottom: 0px;
  right: 10px;
  height: 45px;
  width: 45px;
  border: solid 1px #b4b2b2;
  background: #fff;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  line-height: 42px;
  margin-bottom: 10px;
}

.c-pro-upload img {
  width: 50%;
}

.customerid {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0 16px 0;
}

.customerid span {
  color: #6a9b13;
}

/* User Page CSS Start */
.user-module {
  padding: 27px 0 23px;
}

.user-tab-block {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px;
}
.check-box-wrap {
  padding: 35px 0;
}
.check-box-wrap2 {
  padding: 10px 0 20px 0;
  line-height: 30px;
}
.userprofile button {
  padding: 12px 13px;
  background: #86bc25;
  border: 1px solid #86bc25;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all ease 0.4s;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-weight: 700;
  min-width: 120px;
  margin-right: 16px;
}

.userprofile button.style-two {
  background: #fff;
  color: #b4b2b2;
  border: solid 1px #b4b2b2;
}
.user-tab-block .form-check-label {
  line-height: 30px;
  padding-left: 10px;
}
.user-tab-block .nav-tabs .nav-link.active,
.user-tab-block .nav-tabs .nav-item.show .nav-link {
  margin: 0;
  border-bottom: 2px solid #007cb0;
}
.user-tab-block .nav-tabs .nav-link.active,
.user-tab-block .nav-tabs .nav-item.show .nav-link {
  color: #000;
}
.user-tab-block .nav-tabs .nav-link {
  border: none;
  font-size: 14px;
  color: #d0d0ce;
  font-weight: 600;
}

button.delete-user {
  background-color: #e42313;
  border: solid 1px #e42313;
}
textarea.form-control {
  min-height: 100px;
  font-size: 1rem !important;
}
.form-textarea{
  resize:both
}
.active_span {
  line-height: 25px;
  font-size: 13px;
}

.active_span input {
  margin-right: 6px;
}
.c_spacing {
  margin-top: -10px;
}

.customerpopup .modal-dialog {
  max-width: 750px;
}
.check-box-wrap2 {
  padding: 10px 0 20px 0;
  line-height: 30px;
}

.customerpopup .g-4,
.customerpopup .add_button.gx-4 {
  --bs-gutter-x: 2rem;
}

.module label {
  line-height: 30px;
  padding: 0 2px;
}

.module .form-group {
  margin-bottom: 30px;
}

ul.totalcount li {
  line-height: 32px;
}

.piechart {
  margin: 83px;
}
/* User Page CSS End */

/* progress bar*/
.progress-box {
  border: solid 1px #e8e8e8;
}

.progress-header {
  width: 100%;
  border-bottom: solid 1px #e8e8e8;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    3deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
}

.playicon {
  background: #000;
  line-height: 54px;
  text-align: center;
  width: 54px;
  height: 54px;
  float: left;
}

.playwrapper p {
  line-height: 25px;
  margin-top: 14px;
  margin-left: 61px;
}

.progress-main {
  margin: 0 25px;
}

.progress-main input[type="text"] {
  width: 180px;
  min-width: 180px;
  font-size: 13px;
}

.progress-main input[type="date"] {
  width: 132px;
  min-width: 132px;
  font-size: 13px;
  color: #000;
}

.perctange_bloc {
  margin-right: 26px;
  font-size: 13px;
  font-weight: 700;
  margin-top: -10px;
}

.perctange_bloc p {
  margin-bottom: 0px;
  margin-top: 10px;
  padding-bottom: 0px;
}

.perctange_bloc .progress {
  height: 1.3rem;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
}

.main-outer-task {
  margin-top: 80px;
}
label {
  width: 300px;
  margin-bottom: 10px;
  line-height: 26px;
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: flex-start;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.marginTopCls {
  margin-top: -20px !important;
}
.cancelBtnCls {
  border: solid 1px #b4b2b2 !important;
  background: #fff !important;
  color: #b4b2b2 !important;
}
.field-requirements-input {
  height: 38px !important;
}
.field-requirements-col .btn-number {
  border-radius: 0;
}
.field-requirements-col .field-requirements-input {
  max-width: 60px;
  margin: 0 5px !important;
  padding: 10px !important;
  text-align: center;
}
.progressCheckbox {
  padding: 10px 10px !important;
}
.pdfbutton {
  position: absolute;
  top: 30px;
  right: 220px !important;
  background-color: #bfbfc6;
  color: #fff;
  border: none;
  border-radius: 10px;
}

/* not authorize */
.not_authorize {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.not_authorize img {
  width: 200px;
}
.not_authorize h1 {
}
